import React from "react";
import PropTypes from "prop-types";

export default function SignupLinkHeader({ companyName }) {
  return (
    <div className="head">
      <p className="head-title">{`Signup Link: ${companyName}`}</p>
    </div>
  );
}

SignupLinkHeader.propTypes = {
  companyName: PropTypes.string.isRequired,
};
