import {
  axiosSessionGet, axiosSessionPost, axiosPost, onTokenChange,
  API_BASE,
} from "../http";

// Endpoints
const DASHBOARD_API_BASE = `${API_BASE}enterprise/`;

const LOG_IN = `${API_BASE}token/`;
const LOG_OUT = `${API_BASE}token/logout/`;
const ME = `${API_BASE}accounts/me/`;
const CHANGE_PASSWORD = `${API_BASE}accounts/change_password/`;
const REGISTER = (link) => `${DASHBOARD_API_BASE}register/?link=${link}`;
const CORPORATES = `${DASHBOARD_API_BASE}corporates/`;
const CORPORATE = (id) => `${CORPORATES}${id}/`;
const CORPORATE_DEMOGRAPHICS = (id) => `${CORPORATE(id)}demographics/`;
const CORPORATE_INTERESTS = (id) => `${CORPORATE(id)}interests/`;
const CORPORATE_HEALTH = (id) => `${CORPORATE(id)}health/`;
const CORPORATE_ENGAGEMENT = (id) => `${CORPORATE(id)}engagement/`;
const CORPORATE_COINS = (id) => `${CORPORATE(id)}coins/`;
const CORPORATE_REPLACE_SIGNUP_LINK = (id) => `${CORPORATE(id)}replace-signup-link/`;

export const COMPANIES_DATA = "/api/v1/companies";

// Axios Requests

const logIn = async (email, password) => axiosPost(LOG_IN, { email, password }).then(
  (response) => {
    if (response?.access) {
      onTokenChange(response.access);
    }
    return response.user;
  },
);

const logOut = async () => axiosSessionPost(LOG_OUT, {}).then(
  () => onTokenChange(null),
);

const getMe = async () => {
  try {
    return await axiosSessionGet(ME);
  } catch (error) {
    return null;
  }
};

const changePassword = async (newPassword, confirmPassword) => axiosSessionPost(
  CHANGE_PASSWORD,
  { new_password: newPassword, new_password2: confirmPassword },
);

const getCorporates = async () => axiosSessionGet(CORPORATES).then((response) => response.results);
const getCorporate = async (id) => axiosSessionGet(CORPORATE(id));
const getCorporateDemographics = async (id) => {
  try {
    const { demographics: results } = await axiosSessionGet(CORPORATE_DEMOGRAPHICS(id));
    return results;
  } catch (error) {
    return [];
  }
};
const getCorporateInterests = async (id) => {
  try {
    const { interests: results } = await axiosSessionGet(CORPORATE_INTERESTS(id));
    return results;
  } catch (error) {
    return [];
  }
};
const getCorporateHealth = async (id) => {
  try {
    const { health: results } = await axiosSessionGet(CORPORATE_HEALTH(id));
    return results;
  } catch (error) {
    return [];
  }
};
const getCorporateEngagement = async (id) => {
  try {
    const { engagement: results } = await axiosSessionGet(CORPORATE_ENGAGEMENT(id));
    return results;
  } catch (error) {
    return [];
  }
};
const getCorporateCoins = async (id) => {
  try {
    const { coins: results } = await axiosSessionGet(CORPORATE_COINS(id));
    return results;
  } catch (error) {
    return [];
  }
};
const getRegister = async (link) => axiosSessionGet(REGISTER(link))
  .then((response) => response)
  .catch((error) => Promise.reject(error.response.data));

const register = async (firstName, lastName, username, email, password, isDependent, link) => axiosPost(REGISTER(link), {
  first_name: firstName, last_name: lastName, username, email, password, is_dependent: isDependent,
})
  .then((response) => response.user);

const replaceSignupLink = async (id) => axiosSessionPost(
  CORPORATE_REPLACE_SIGNUP_LINK(id),
);

export {
  logIn,
  logOut,
  getMe,
  changePassword,
  getCorporates,
  getCorporate,
  getCorporateDemographics,
  getCorporateInterests,
  getCorporateHealth,
  getCorporateEngagement,
  getCorporateCoins,
  getRegister,
  register,
  replaceSignupLink,
};
