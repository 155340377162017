import { configConsumerProps } from "antd/lib/config-provider";
import axios from "axios";
import { API_URL } from "../config";

const config = {
  baseURL: `${API_URL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 15000,
  maxRedirects: 5,
  maxContentLength: 100000,
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
  withCredentials: true,
};

const API_BASE = "/v1/api/";

const requestHandler = axios.create(config);

const axiosGet = async (url, params = {}) => requestHandler
  .get(url, { params })
  .then((response) => response.data)
  .catch((error) => Promise.reject(error));

const axiosPost = async (url, data, params = {}) => requestHandler
  .post(url, data, { params })
  .then((response) => response.data)
  .catch((error) => Promise.reject(error));

class ApiClient {
  session;

  constructor() {
    this.session = axios.create({ ...configConsumerProps, adapter: caches.adapter, ...config });
    this.onInit();
  }

  onInit() {
    this.session.interceptors.request.use(this.onRequestSuccess, this.onRequestError);
    this.session.interceptors.response.use(this.onResponseSuccess, this.onResponseError);
  }

  static onRequestSuccess = (response) => response;

  static onRequestError = (error) => error;

  static onResponseSuccess = (response) => response;

  onResponseError = (error) => {
    const originalConfig = error.config;
    if (error.response?.status === 401 && originalConfig && !originalConfig.isRetryRequest) {
      return Promise((resolve, reject) => this.getNewAccessToken()
        .then((res) => {
          originalConfig.isRetryRequest = true;
          originalConfig.headers.Authorization = `Bearer ${res.access}`;
          resolve(axios(originalConfig));
        })
        .catch((e) => reject(e)));
    }
    return Promise.reject(error);
  };

  onTokenChange = (token) => {
    this.session.defaults.headers.common.Authorization = token ? `Bearer ${token}` : null;
  };

  getNewAccessToken = async () => axiosPost(`${API_BASE}token/refresh/`, {})
    .then((data) => {
      this.onTokenChange(data.access);
      return data;
    })
    .catch(
      (e) => Promise.reject(e),
    );
}

const client = new ApiClient();
const { session, onTokenChange, getNewAccessToken } = client;

const axiosSessionGet = async (url, params = {}) => session
  .get(url, { params })
  .then((response) => response.data)
  .catch((error) => Promise.reject(error));

const axiosSessionPost = async (url, data, params = {}) => session
  .post(url, data, { params })
  .then((response) => response.data)
  .catch((error) => Promise.reject(error));

export {
  API_BASE,
  axiosGet,
  axiosSessionGet,
  axiosPost,
  axiosSessionPost,
  session,
  onTokenChange,
  getNewAccessToken,
};
