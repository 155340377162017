import React, { useState, useEffect } from "react";
import { getRegister } from "../services/callApi";
import { SignupLink, RegisterDependents } from "../contexts/register";
import { AuthLayout } from "../layouts";
import { LeftPanel } from "../components/Register";

function Register() {
  const [signupLink, setSignupLink] = useState();
  const [corporateName, setCorporateName] = useState();
  const [registerDependents, setRegisterDependents] = useState();
  const [linkError, setLinkError] = useState();

  useEffect(() => {
    const loadData = async () => {
      const signupLink = window.location.search.substring(1);
      if (!signupLink) {
        setLinkError('Invalid link')
        setCorporateName(null)
        setRegisterDependents(false)
      } else {
        setSignupLink(signupLink);
        await getRegister(signupLink)
          .then(r => {
            setCorporateName(r.corporateName)
            setRegisterDependents(r.registerDependents)
            setLinkError(null)
          })
          .catch(e => {
            setLinkError(e.error);
            return;
          })
      }
    }
    loadData();
  }, []);

  return <>
    <SignupLink.Provider value={signupLink}>
      <RegisterDependents.Provider value={registerDependents}>
        <AuthLayout>
          {
            linkError ?
              <p>
                {linkError}
              </p>
            :
            <LeftPanel title={corporateName} />
          }
        </AuthLayout>
      </RegisterDependents.Provider>
    </SignupLink.Provider>
  </>;
}

export default Register;
