import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import { useSelector } from "react-redux";
import coinsImg from "../../assets/svgs/coin1.svg";
import coinImg from "../../assets/svgs/coin.svg";
import { CoinsStyle } from "./style";

function AccruedCoins({ coins, value }) {
  const type = "Accrued coins";
  const { loading } = useSelector((state) => state.loading.value);
  const { isOpen } = useSelector((state) => state.sidebar.value);

  return (
    <CoinsStyle type={type} isOpen={isOpen}>
      <div className="texts">
        <p className="type">{type}</p>
        <p className="coins">{loading ? "..." : coins}</p>
        <span className="line" />
        <p className="value">{loading ? "..." : value}</p>
      </div>
      <img src={coinsImg} className="coin-img head" alt="Coin-head" />
      <img src={coinImg} className="coin-img coin" alt="Coins" />
    </CoinsStyle>
  );
}

function RedeemedCoins({ coins, value, redemptions }) {
  const type = "Redeemed coins";
  const { loading } = useSelector((state) => state.loading.value);
  const { isOpen } = useSelector((state) => state.sidebar.value);
  const [chart, setChart] = useState(<></>);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
      },
    },
  };
  useEffect(
    () => {
      const chartData = {
        labels: redemptions.map((e) => e.label),
        datasets: [
          {
            data: redemptions.map((e) => e.percentage),
            backgroundColor: redemptions.map(() => "#73314F"),
            hoverBackgroundColor: "#9E6F85",
            borderRadius: "8",
          },
        ],
      };
      setChart(<Chart
        type="bar"
        data={chartData}
        options={chartOptions}
        className="chart"
      />);
    },
    [redemptions],
  );

  return (
    <CoinsStyle type={type} isOpen={isOpen}>
      <div className="texts">
        <p className="type">{type}</p>
        <p className="coins">{loading ? "..." : coins}</p>

        <span className="line" />
        <p className="value">{loading ? "..." : value}</p>
      </div>
      {chart}
    </CoinsStyle>
  );
}

export { AccruedCoins, RedeemedCoins };
