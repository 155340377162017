import React, { useState } from "react";
import { redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { SetRegisterSuccess } from "../../../contexts/register";
import Button from "../Button";
import { Form, FormField, SubmitButton } from "../..";
import { Container, TitleWrapper } from "./styles";
import Tabs from "./Tabs";
import Title from "./Title";

export default function LeftSide({ title }) {
  const url = window.location.search.substring(1);
  const [registerSuccess, setRegisterSuccess] = useState(false);

  return (
    <SetRegisterSuccess.Provider value={setRegisterSuccess}>
      <Container>
        {registerSuccess ?
          <>
            <p>Success!</p>
            <p>Check your emails for instructions for how to access SORA.</p>
          </> :
          <>
            <Title title={title} />
            <Tabs />
          </>
        }
      </Container>
    </SetRegisterSuccess.Provider>
  );
}

LeftSide.propTypes = {
  title: PropTypes.string.isRequired,
};
