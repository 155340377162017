import React from "react";

import "../../styles/typography.css";

import { StatisticStyle } from "./styles";

export const Statistic = ({ number, title }) => <>
  <StatisticStyle>
    <span className="statistic-number">{number}</span>
    <p className="statistic-title">{title}</p>
  </StatisticStyle>
</>
