import React from "react";
import { Chart as ChartComponents } from "primereact/chart";
// import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ChartStyle } from "./style";
import MyLoader from "../Loader";

function EngagementChart({ type, data, options }) {
  const { loading } = useSelector((state) => state.loading.value);
  const { isOpen } = useSelector((state) => state.sidebar.value);

  return (
    <ChartStyle isOpen={isOpen}>
      {loading ? (
        <MyLoader type={type} />
      ) : (
        <>
          <p>Top features used</p>
          <div className="chart">
            <ChartComponents
              type="doughnut"
              data={data}
              options={options}
              style={{ height: "90%" }}
            />
          </div>
        </>
      )}
    </ChartStyle>
  );
}
function InterestsChart({ type, data, options }) {
  const { loading } = useSelector((state) => state.loading.value);
  const { isOpen } = useSelector((state) => state.sidebar.value);

  return (
    <ChartStyle isOpen={isOpen}>
      {loading ? (
        <MyLoader type={type} />
      ) : (
        <>
          <p>Top topics of interest</p>
          <div className="chart">
            <ChartComponents
              type="bar"
              data={data}
              options={options}
              style={{ height: "90%" }}
            />
          </div>
        </>
      )}
    </ChartStyle>
  );
}
function HealthChart({
  type, data, topData, options,
}) {
  const { loading } = useSelector((state) => state.loading.value);
  const { isOpen } = useSelector((state) => state.sidebar.value);

  return (
    <ChartStyle isOpen={isOpen}>
      {loading ? (
        <MyLoader type={type} />
      ) : (
        <>
          <div className="texts">
            <p>Health conditions</p>
            <p className="text2">Based on the logged data of your employees in SORA.</p>
            {/* <Link to="/dashboard/charts">Find resources</Link> */}
          </div>

          <div className="chart-bar">
            <ChartComponents
              type="bar"
              data={data}
              options={options}
              style={{ height: "90%" }}
            />
            <div className="topThree">
              <p className="topThree-title">Top 3 Health conditions</p>
              {topData.map(({ label, percentage }, index) => (
                <div className="topthree-item" key={index}>
                  {" "}
                  <span className="top-number">
                    {" "}
                    {index + 1}
                    #
                    {" "}
                  </span>
                  <p className="top-item">
                    {label}
                    {" "}
                    -
                    {Math.round(percentage)}
                    {" "}
                    %
                  </p>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </ChartStyle>
  );
}

export { EngagementChart, InterestsChart, HealthChart };
