import * as Yup from "yup";

const registerSchema = Yup.object({
  first_name: Yup.string()
    .required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  username: Yup.string().required("A username is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
  password_repeat: Yup.string().oneOf([Yup.ref("password"), null], "Passwords don't match").required("Password repeat is required"),
});

export default registerSchema;
