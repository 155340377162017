import * as Yup from "yup";

const passwordChangeSchema = Yup.object({
  oldPassword: Yup.string()
    .required("Old password is required"),

  newPassword: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
      "Must contain 8 characters and include uppercase, lowercase, numeric and special characters",
    )
    .required("New password is required"),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Must match new password")
    .required("New password confirmation is required"),
});

export default passwordChangeSchema;
