import React from "react";

function LogoutButton() {
  return (
    <button
      type="button"
      className="btn btn-danger btn-block"
    >
      Sign Out
    </button>
  );
}

export default LogoutButton;
