import React from "react";
import { Datastyle } from "./style";
import UserData from "./UserData";

export default function Data({
  firstName, lastName, email, isEdit, setIsEdit,
}) {
  return (
    <>
      {isEdit ? (
        <UserData setIsEdit={setIsEdit} />
      ) : (
        <Datastyle>
          <div>
            <p>Name:</p>
            <span>{`${firstName} ${lastName}`}</span>
          </div>
          <div>
            <p>Email:</p>
            <span>{email}</span>
          </div>
        </Datastyle>
      )}
    </>
  );
}
