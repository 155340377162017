import PropTypes from "prop-types";
import React from "react";
import SubscriptionWrapper from "./styles";
import { subscriptionName } from "../../../utils";

export default function Subscription({ type }) {
  return <SubscriptionWrapper type={type}>{subscriptionName(type)}</SubscriptionWrapper>;
}

Subscription.prototypes = {
  type: PropTypes.string.isRequired,
};
