import React, { useEffect, useState } from "react";

import { getCorporateDemographics } from "../../services/callApi";

import { Statistic } from "./Statistic";
import { StatisticsStyle } from "./styles";

export const Statistics = ({ company }) => {
  const [data, setData] = useState()
  const employees = company.registered_employees;
  const openInvitations = company.num_open_invitations;

  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const demographics = await getCorporateDemographics(company.id);
        setData(demographics)
      }
    };
    fetchData();
  }, [company])

  return ((data != null && employees != null && openInvitations != null) ? 
    <StatisticsStyle>
      <Statistic number={employees} title="Enrolled Employees" />
      <Statistic number={openInvitations} title="Open Invitations" />
      {data.map(({ count, label }, index) => (
        <Statistic key={index} number={count} title={label} />
      ))}
    </StatisticsStyle> : 
    <></>
  );
}
