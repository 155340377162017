import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCorporateInterests,
  getCorporateHealth,
  getCorporateEngagement,
  getCorporateCoins,
} from "../services/callApi";
import { setLoading } from "../store/loading";

const useCharts = (companyId) => {
  const [engagementData, setEngagementData] = useState([]);
  const [interestsData, setInterestsData] = useState([]);
  const [healthData, setHealthData] = useState({ all: [], top: [] });
  const [coinData, setCoinData] = useState({ accrued: {}, redeemed: {}, redemptions: [] });
  const dispatch = useDispatch();

  useEffect(() => {
    if (companyId === 0) return;
    if (typeof companyId === "undefined") return;
    const fetchData = async () => {
      try {
        dispatch(setLoading({ loading: true }));
        const engagements = getCorporateEngagement(companyId);
        const interests = getCorporateInterests(companyId);
        const health = getCorporateHealth(companyId);
        const coins = getCorporateCoins(companyId);
        setEngagementData(await engagements);
        setInterestsData(await interests);
        setHealthData(await health);
        setCoinData(await coins);
        dispatch(setLoading({ loading: false }));
        return engagements;
      } catch (error) {
        return error;
      }
    };
    fetchData();
  }, [companyId]);

  return {
    engagementData,
    interestsData,
    healthData,
    coinData,
  };
};

export default useCharts;
