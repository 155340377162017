import PropTypes from "prop-types";
import React from "react";
import AppSubscriptionWrapper from "./styles";
import { appSubscriptionName } from "../../../utils";

export default function AppSubscription({ type }) {
  return <AppSubscriptionWrapper type={type}>{appSubscriptionName(type)}</AppSubscriptionWrapper>;
}

AppSubscription.prototypes = {
  type: PropTypes.string.isRequired,
};
