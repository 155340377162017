import React from "react";
import EmptySection from "./style";
import empty from "../../assets/svgs/Empty.svg";

export default function Empty({ page }) {
  return (
    <EmptySection>
      {page === "admin" ? (
        <>
          <p className="title">No employers added</p>
          <p className="desc">There are no employers set up</p>
          <img src={empty} alt="Empty" />
        </>
      ) : (
        <>
          <p className="title">No employees joined SORA</p>
          <p className="desc">There aren’t employees using the SORA app.</p>
          <img src={empty} alt="Empty" />
        </>
      )}
    </EmptySection>
  );
}
