import React, { useState, useContext } from "react";

import { register } from "../../../services/callApi";
import { SignupLink, SetRegisterSuccess, RegisterDependents } from "../../../contexts/register";
import { registerSchema } from "../../../utils/validation";
import { Form, FormField, SubmitButton } from "../..";
import { LoginDiv } from "./styles";

export default function FormRegister() {
  const setRegisterSuccess = useContext(SetRegisterSuccess);

  const signupLink = useContext(SignupLink);

  const registerDependents = useContext(RegisterDependents);

  const [submitting, setSubmitting] = useState(false);

  const initialValues = {
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: "",
    password_repeat: "",
    is_dependent: false,
  };

  const handleSubmit = async (values, actions) => {
    setSubmitting(true);
    try {
      await register(
        values.first_name,
        values.last_name,
        values.username,
        values.email,
        values.password,
        values.is_dependent,
        signupLink)
      setSubmitting(false);
      setRegisterSuccess(true);
    } catch (error) {
        Object.entries(error.response.data).map(e => actions.setFieldError(e[0], e[1]))
        setSubmitting(false);
        return;
    }
  };

  return <>
    <Form initialValues={initialValues} onSubmit={handleSubmit} validationSchema={registerSchema}>
      {({ errors }) => (
        <LoginDiv>
          <div className="inputs">
            <FormField name="first_name" placeholder="First Name*" errors={errors} />
            <FormField name="last_name" placeholder="Last Name*" errors={errors} />
            <FormField name="username" placeholder="Username*" errors={errors} />
            <FormField name="email" placeholder="Email*" errors={errors} />
            <FormField
              name="password"
              placeholder="Password*"
              type="password"
              errors={errors}
            />
            <FormField
              name="password_repeat"
              placeholder="Password Repeat*"
              type="password"
              errors={errors}
            />
            {
              registerDependents ?
              <label>
                Partner/dependent?
                <FormField type="checkbox" name="is_dependent" errors={errors} />
              </label> :
              <></>
            }
          </div>
          <SubmitButton title="Register" submitting={submitting}/>
        </LoginDiv>
      )}
    </Form>
  </>
}
