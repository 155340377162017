import React from "react";
import { useSelector } from "react-redux";
import {
  EngagementChart,
  InterestsChart,
  // HealthChart,
} from "./Chart";
import { AccruedCoins, RedeemedCoins } from "../Coins";
import { Wrapper } from "./style";
import { CoinsWrapper } from "../Coins/style";
import ChartsData from "./data";

export default function Charts({ company }) {
  const { isOpen } = useSelector(state => state.sidebar.value);
  const {
    engagementChartData,
    engagmentChartOptions,
    interestsChartData,
    interestsChartOptions,
    // healthChartData,
    // healthChartOptions,
    // topHealthData,
    coinData,
  } = ChartsData(company.id);

  return (
    <>
      <Wrapper isOpen={isOpen}>
        <EngagementChart
          type="doughnut"
          data={engagementChartData}
          options={engagmentChartOptions}
        />
        <InterestsChart
          type="bar"
          data={interestsChartData}
          options={interestsChartOptions}
        />
      </Wrapper>
      <CoinsWrapper isOpen={isOpen}>
        <AccruedCoins
          coins={`${coinData.accrued.number} coins`}
          value={`£${coinData.accrued.value}`}
        />
        <RedeemedCoins
          coins={`${coinData.redeemed.number} coins`}
          value={`£${coinData.redeemed.value}`}
          redemptions={coinData.redemptions}
        />
      </CoinsWrapper>
      {/* <HealthChart
        type="bar-health"
        data={healthChartData}
        options={healthChartOptions}
        topData={topHealthData}
      /> */}
    </>
  );
}
