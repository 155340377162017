import React from "react";

import { ContactStyle } from "../styles/dashboard";
import subscribe from "../assets/svgs/Subscribe.svg";
import { Mailto } from "../components";

export default function Contact() {
  return (
    <ContactStyle>
      <p>Have any questions?</p>
      <p className="contact-email">If you have any issues, please contact</p>
      <Mailto email="info@syronahealth.com" className="email">
        info@syronahealth.com
      </Mailto>
      <img src={subscribe} className="image" alt="Subscribe" />
    </ContactStyle>
  );
}
