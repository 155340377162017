function subscriptionName(type) {
  let name = "";
  switch (type) {
    case "lite":
      name = "Lite";
      break;
    case "core":
    case "legacy_premium":
      name = "Core";
      break;
    case "premium":
      name = "Premium";
      break;
    default:
      name = "N/A";
  }

  return name;
}

function appSubscriptionName(type) {
  let name = "";
  switch (type) {
    case "employee-paid":
      name = "Employee-Paid";
      break;
    case "employer-paid":
    default:
      name = "Employer-Paid";
  }

  return name;
}

export { subscriptionName, appSubscriptionName };
