import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LeftPanel } from "../components/Auth/LeftPanel";
import { AuthLayout } from "../layouts";
import { setUser } from "../store";
import { getNewAccessToken } from "../http";
import { logOut, getMe, getCorporates } from "../services/callApi";

export default function LogIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const url = window.location.search.substring(1);
  const { user, employee } = useSelector((state) => state.user);

  useEffect(() => {
    const checkLogOut = async () => {
      if (url === "logout") {
        await logOut();
        dispatch(setUser({ user: null, employee: null }));
        navigate("/auth?login");
      }
    };
    checkLogOut();
  });

  useEffect(() => {
    const attemptToRecoverSession = async () => {
      if (!user || !employee) {
        await getNewAccessToken()
          .then(async () => {
            dispatch(setUser(await getMe()));
          }).then(async () => {
            getCorporates()
          }).then(async data => {
            if (data.length > 1) {
              navigate("/admin");
            } else {
              navigate("/");
            }
          }).catch(() => {
            dispatch(setUser({ user: null, employee: null }));
            return;
          });
      }
    };
    if (url == "" ){
      attemptToRecoverSession(); // eslint-disable-line no-console
    }
  }, [user, employee]);

  let title = "";
  if (url === "set-password") {
    title = "Set your password";
  } else if (url === "verify-email") {
    title = "Verify Email";
  } else if (url === "forgot-password") {
    title = "Forgot password";
  } else {
    title = "Welcome to Syrona Health";
  }

  return (
    <AuthLayout>
      <LeftPanel title={title} />
    </AuthLayout>
  );
}
