import { useFormikContext } from "formik";
import React from "react";
import { SubmitStyle } from "../../../styles/FormStyle";

export default function SubmitButton({ title, ...args }) {
  const { handleSubmit, isValid } = useFormikContext();
  const { submitting, ...otherArgs } = args;
  return (
    <SubmitStyle>
      <button type="submit" onClick={handleSubmit} disabled={!isValid||submitting} className="btn" {...otherArgs}>
        {title}
      </button>
    </SubmitStyle>
  );
}
