import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "../../components/Loader";
import { DashboardLayout } from "../../layouts";
import { LogIn, Register } from "../../views";
import routes from "./routes";

export default function AllRoutes() {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/auth" element={<LogIn />} />
        <Route path="/" element={<DashboardLayout />}>
          {routes.map(({ key, path, Element }) => {
            if (path === "") {
              return <Route index key={key} element={<Element />} />;
            }
            return <Route key={key} path={path} element={<Element />} />;
          })}
        </Route>
        <Route path="/register" element={<Register />} />
      </Routes>
    </Suspense>
  );
}
