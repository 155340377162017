import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getNewAccessToken } from "../http";
import { getMe } from "../services/callApi";
import { setUser } from "../store";
import Sidebar from "../components/Dashboard/Sidebar";
import Header from "../components/Dashboard/DashHeader";
// import Subscribe from "../components/Subscribe/Subscribe";
import { DashboardWrapper } from "./styles";

export default function DashboardLayout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { open } = useSelector((state) => state.upgrade.value);
  const { isOpen } = useSelector((state) => state.sidebar.value);
  const { user, employee } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const attemptToRefreshMe = async () => {
      setIsLoading(true)
      if (!user || !employee) {
        await getNewAccessToken()
          .then(() => getMe())
          .then((me) => dispatch(setUser(me)))
          .then(() => setIsLoading(false))
          .catch(() => {
            dispatch(setUser({ user: null, employee: null }));
            navigate("/auth?login");
            return;
          });
      }
    };
    attemptToRefreshMe();
  }, [user, employee]);

  return <>
    { isLoading
      ? <></>
      : <DashboardWrapper className="page-continer" isOpen={isOpen}>
          <div className="main-page">
            <Header />
            <div className="main-content">
              {/* {open && <Subscribe />} */}
              <Outlet />
            </div>
          </div>
        <Sidebar />
      </DashboardWrapper>
    }
  </>
}
