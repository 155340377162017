import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getMe, getCorporate } from "../services/callApi";
import { setUser } from "../store";
import WelcomeCarousel from "../components/Popup";
import Statistics from "../components/Statistics";
import Charts from "../components/Charts/Charts";
import Empty from "../components/Empty";
import MyLoader from "../components/Loader";
import Filter from "../components/Dashboard/Filter";

function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, employee } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const [companyId, setCompanyId] = useState();
  const [company, setCompany] = useState();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      const c = window.location.search.substring(1) !== "" ? window.location.search.substring(1) : employee.corporate.id
      setCompanyId(c);
      await getCorporate(c)
        .then(c => setCompany(c))
        .then(() => setIsLoading(false))
        .catch(() => {
          dispatch(setUser({ user: null, employee: null }));
          navigate("/auth?login");
          return;
        });
    };
    fetchData();
  }, [user, employee]);

  return (
    <>
      <WelcomeCarousel />
      {isLoading ? (
        <MyLoader />
      ) : (company && companyId) ? (
        <>
          <Filter company={company} />
          <Statistics company={company} />
          <Charts company={company} />
        </>
      ) : (
        <Empty page="" />
      )}
    </>
  );
}

export default Dashboard;
