import React, { useState } from "react";
import { useSelector } from "react-redux";
import Data from "../components/Account/Data";
import AccountHeader from "../components/Account/Header";
import { AccountStyle } from "../styles";

function Account() {
  const { user } = useSelector((state) => state.user);

  const [isEdit, setIsEdit] = useState(false);

  return (
    <AccountStyle>
      <AccountHeader setIsEdit={setIsEdit} isEdit={isEdit} />
      <Data
        firstName={user.first_name}
        lastName={user.last_name}
        email={user.email}
        setIsEdit={setIsEdit}
        isEdit={isEdit}
      />
    </AccountStyle>
  );
}

export default Account;
