import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getCorporate, replaceSignupLink } from "../services/callApi";
import SignupLinkHeader from "../components/SignupLink/Header";

function SignupLink() {
  const { user } = useSelector((state) => state.user);

  const [companyId, setCompanyId] = useState();
  const [company, setCompany] = useState();
  const [fullSignupLink, setFullSignupLink] = useState();
  const [isLoaded, setIsLoadded] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      setIsLoadded(false)
      const cId = window.location.search.substring(1);
      setCompanyId(cId);
      await getCorporate(cId).then(c => {
        setCompany(c)
        setFullSignupLink(createFullSignupLink(c))
        setIsLoadded(true)
      })
    }
    loadData();
  }, [user]);

  const onClick = () => {
    replaceSignupLink(companyId).then(c => {
      setFullSignupLink(createFullSignupLink(c))
    })
  }

  const createFullSignupLink = c => c.signup_link_suffix && `${window.location.origin}/register?${c.signup_link_suffix}`

  return <>
    <SignupLinkStyle>
      {isLoaded &&
        <>
          <SignupLinkHeader companyName={company.name} />
          <p>
          {fullSignupLink ? <>Active Signup Link: <a href={fullSignupLink}>{fullSignupLink}</a></> : "No active signup link"}
          </p>
          <ReplaceLinkButton onClick={onClick}>
            Replace Signup Link
          </ReplaceLinkButton>
        </>
      }
    </SignupLinkStyle>
  </>;
}

export default SignupLink;

const SignupLinkStyle = styled.div`
  box-shadow: 1px 1px 4px 2px #e1e1e1;
  width: 85%;
  border-radius: 15px;
  padding: 2.5rem;
  height: 100%;
  max-height: 100%;
  background-color: ${({ theme: { colors } }) => colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.2rem;
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      font-family: "DM Serif Text", sans-serif;
      font-size: 1.4rem;
      text-align: left !important;
    }
  }
`;

const ReplaceLinkButton = styled.button`
  background: ${({ theme: { colors }, disabled }) => !disabled ? colors.primary : colors.lightGray};
  color: ${({ theme: { colors }, disabled }) => colors.white};
  padding: 0.5rem 2rem;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  margin-top: 0.5rem;
  width: 20rem;
  @media screen and (max-width: 800px) {
    width: 40%;
}
`;
