const theme = {
  colors: {
    primary: "#73314F",
    primaryDark: "#292929",
    primaryLight: "#FBFBFB",
    secondary: "#CCDDDE",
    secondaryGreen: "#275C61",
    black: "#000",
    white: "#fff",
    accordion: "#F6F6F6",
    checkoutGray: "#F5F4F4",
    error: "#DA1E28",
    productCard: "#F6F6F6",
    lightGreen: "#E6EDED",
    emptyBasket: "#6F6F6F",
    white1: "#FEFEFE",
    lightGray: "#DFDFDF",
    lightGray1: "#92969D",
    lightGray2: "#737B7D",
    lightGray3: "#ECF0F1",
    lightGray4: "#6E798C",
    whiteHover: "`rgba(255,255,255,0.2)`",
    greenOverlay: "rgba(39,92,97,0.4)",
    transparent: "transparent",
    darkGreen: "rgba(39,92,97,1)",
    check: "#1D813B",
  },
  sizes: {
    MENU_WIDTH: 280,
    COLLAPSED_MENU_WIDTH: 80,
  },
  screens: {
    mobile: "600px",
    tablet_portrait: "990px",
    tablet_landscape: "1200px",
    desktop: "1440px",
    wide_screen: "1440px",
  },
};

export default theme;
