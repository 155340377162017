import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import PropTypes from "prop-types";

import { getNewAccessToken } from "../../http";
import { getCorporates, getMe } from "../../services/callApi";
import { setUser } from "../../store";
import {
  AutoCompleteStyle,
  AutoCompleteContainer,
  TableTitle,
  TableContent,
  StyledTable,
} from "../Dashboard/styles";
import Empty from "../Empty";

import Actions from "./Actions";
import AppSubscription from "./AppSubscription";
import Subscription from "./Subscription";
import Header from "./Header";
// import Emails from "./Emails";


export default function AdminTable({ setIsOpen, isOpen }) {

  const { user } = useSelector((state) => state.user);

  const [dataSource, setDataSource] = useState([]);
  const [filtredDataSource, setFiltredDataSource] = useState([]);
  const [sort, setSort] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [corporatesLoading, setCorproatesLoading] = useState(true);

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    const fetchData = async () => {
      setCorproatesLoading(true)
      getCorporates()
      .then(data => setDataSource(data))
      .catch(() => {})
      .finally(() => setCorproatesLoading(false));
    };
    fetchData();
    return () => {
      setCorproatesLoading(false);
      source.cancel();
    };
  }, [searchValue, sort, user]);

  const handleChange = (value) => {
    setSearchValue(value);
  };

  const onSearchChange = (value) => {
    const filteredData = dataSource.filter((item) => item.name.toUpperCase().includes(value.toUpperCase()));
    setFiltredDataSource(filteredData);
  };

  const columns = [
    {
      title: <TableTitle>Name</TableTitle>,
      dataIndex: "name",
      key: "0",
      align: "center",
      render: (text) => <TableContent>{text}</TableContent>,
    },
    // {
    //   title: <TableTitle>Email addresses</TableTitle>,
    //   dataIndex: "email",
    //   key: "1",
    //   align: "center",
    //   render: (text) => <Emails setIsOpen={setIsOpen} emails={text} />,
    // },
    {
      title: <TableTitle>Coins bonus</TableTitle>,
      dataIndex: "coins",
      key: "2",
      align: "center",
      render: (text) => <TableContent>{text}</TableContent>,
    },
    {
      title: <TableTitle>Employees allowed</TableTitle>,
      dataIndex: "allowed_employees",
      key: "3",
      align: "center",
      render: (text) => <TableContent>{text}</TableContent>,
    },
    {
      title: <TableTitle>Subscription type</TableTitle>,
      dataIndex: "subscription_type",
      key: "4",
      align: "center",
      render: (text) => <Subscription type={text} />,
    },
    {
      title: <TableTitle>Employer/Employee paid</TableTitle>,
      dataIndex: "employees_app_subscription",
      key: "5",
      align: "center",
      render: (text) => <AppSubscription type={text} />,
    },
    {
      title: <TableTitle>Actions</TableTitle>,
      key: "6",
      align: "center",
      render: (record) => <Actions item={record} setDataSource={setDataSource} />,
    },
  ];

  return (
    <>
      {corporatesLoading || dataSource.length > 0 ? (
        <>
          <Header
            setSort={setSort}
            setFiltredDataSource={setFiltredDataSource}
            dataSource={dataSource}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
          />
          <AutoCompleteContainer>
            <AutoCompleteStyle
              onSearch={onSearchChange}
              value={searchValue}
              placeholder="Search"
              onChange={handleChange}
            />
            <div className="icon">
              <AiOutlineSearch />
            </div>
          </AutoCompleteContainer>
          <StyledTable
            columns={columns}
            dataSource={!filtredDataSource.length ? dataSource : filtredDataSource}
            scroll={{
              y: 600,
            }}
            loading={corporatesLoading}
            pagination={false}
          />
        </>
      ) : (
        <Empty page="admin" isOpen={isOpen} setIsOpen={setIsOpen} />
      )}
    </>
  );
}

AdminTable.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};
