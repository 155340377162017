import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LoginDiv } from "./styles";
import { Form, FormField, SubmitButton } from "../..";
import { loginSchema } from "../../../utils/validation";
import { logIn, logOut, getMe, getCorporates } from "../../../services/callApi";
import { setUser } from "../../../store";

export default function FormAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);

  const initialValues = {
    email: "",
    password: "",
  };

  const handleSubmit = async (values, actions) => {
    setSubmitting(true);
    try {
      const user = await logIn(values.email, values.password);
      if (!(user.is_corporate_admin || user.is_staff)) {
        await logOut();
        actions.setFieldError("email", "Not authorised");
        actions.setFieldError("password", "Not authorised");
        setSubmitting(false);
      } else {
        dispatch(setUser(await getMe()))
        await getCorporates()
          .then(async data => {
            if (data.length > 1) {
              navigate("/admin");
            } else {
              navigate("/");
            }
          });
        setSubmitting(false);
      }
    } catch {
        actions.setFieldError("email", "Not authorised");
        actions.setFieldError("password", "Not authorised");
        setSubmitting(false);
        return;
    }
  };

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit} validationSchema={loginSchema}>
      {({ errors }) => (
        <LoginDiv>
          <div className="inputs">
            <FormField name="email" placeholder="Email*" errors={errors} />
            <FormField
              name="password"
              placeholder="Password*"
              type="password"
              errors={errors}
            />
            {/* <Link to="/auth?forgot-password" className="forgot-password">
              Forgot your password?
            </Link> */}
          </div>
          <SubmitButton title="Log in" submitting={submitting}/>
        </LoginDiv>
      )}
    </Form>
  );
}
