import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { FormStyle, CancelButton as CancelButtonStyle, UpdateButton as UpdateButtonStyle } from "./style";
import { Form, FormField } from "..";
import { changePasswordSchema } from "../../utils/validation";
import { logIn, changePassword } from "../../services/callApi";

export default function UserData({ setIsEdit }) {
  const { user } = useSelector((state) => state.user);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (values, actions) => {
    setIsSubmitting(true);
    try {
      await logIn(user.email, values.oldPassword);
    } catch {
      actions.setFieldError("oldPassword", "Password Incorrect");
      setIsSubmitting(false);
      return;
    }
    try {
      await changePassword(values.newPassword, values.confirmPassword);
      alert("Your password has been updated."); // eslint-disable-line no-alert
      setIsSubmitting(false);
      setIsEdit(false);
    } catch (e) {
      alert("Your password was not updated."); // eslint-disable-line no-alert
      setIsSubmitting(false);
      console.error(e); // eslint-disable-line no-console
    }
  };
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={changePasswordSchema}
    >
      {({ errors }) => (
        <>
          <FormStyle>
            <div className="first-col">
              <div>
                <p>Old Password</p>
                <FormField name="oldPassword" placeholder="Old password" type="password" errors={errors} />
              </div>
              <div>
                <p>New Password</p>
                <FormField name="newPassword" placeholder="New password" type="password" errors={errors} />
              </div>
              <div>
                <p>Confirm New Password</p>
                <FormField name="confirmPassword" placeholder="Confirm password" type="password" errors={errors} />
              </div>
              <UpdateButton title="Update" isSubmitting={isSubmitting} />
              <CancelButton title="Cancel" setIsEdit={setIsEdit} />
            </div>
          </FormStyle>
        </>
      )}
    </Form>
  );
}

function UpdateButton({ title, isSubmitting, ...args }) {
  const { handleSubmit, isValid } = useFormikContext();
  return (
    <UpdateButtonStyle type="submit" onClick={handleSubmit} disabled={!isValid || isSubmitting} {...args}>
      {title}
    </UpdateButtonStyle>
  );
}
function CancelButton({ title, setIsEdit, ...args }) {
  return (
    <CancelButtonStyle type="button" onClick={() => setIsEdit(false)} {...args}>
      {title}
    </CancelButtonStyle>
  );
}
