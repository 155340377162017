import React from "react";
import { useNavigate } from "react-router-dom";
import { HiChartBar, HiLink } from "react-icons/hi";

import { ActionsWrapper, Icon } from "./styles";

export default function Actions({ setDataSource, item }) {
  const navigate = useNavigate();

  return (
    <>
      <ActionsWrapper>
        <Icon onClick={() => navigate(`/?${item.id}`)}>
          <HiChartBar />
        </Icon>
        <Icon onClick={() => navigate(`/signup-link/?${item.id}`)}>
          <HiLink />
        </Icon>
      </ActionsWrapper>
    </>
  );
}
